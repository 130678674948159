<template>
  <div>
    <el-button
      icon="el-icon-plus"
      type="primary"
      size="medium"
      @click="$router.push({ name: 'CompanyAdd' })"
    >
      Add Company
    </el-button>

    <el-button
      icon="el-icon-search"
      size="medium"
      @click="$router.push({ name: 'PropertySearch' })"
    >
      Property Search
    </el-button>

    <el-dropdown
      trigger="click"
      placement="bottom-start"
      :hide-on-click="false"
      @command="handleExport"
    >
      <el-button
        size="medium"
        icon="el-icon-download"
      >
        Export
        <i class="el-icon-arrow-down el-icon--right" />
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          :icon="loadingCompanyExport ? 'el-icon-loading' : ''"
          command="exportCompanyData"
        >
          Companies
        </el-dropdown-item>
        <el-dropdown-item
          :icon="loadingPropertyExport ? 'el-icon-loading' : ''"
          command="exportPropertyData"
        >
          Properties
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: 'CompanyListControls',
  props: {
    loadingPropertyExport: {
      type: Boolean,
      default: false
    },
    loadingCompanyExport: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * Handler for when an item from the export dropdown is clicked.
     *
     * @param {String} command
     */
    handleExport (command) {
      this.$emit(command)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown {
  margin-left: 10px;
}
</style>
