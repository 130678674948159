<template>
  <div>
    <div class="table-filters">
      <el-input
        v-model="searchValue"
        size="small"
        placeholder="Search"
        class="search"
        clearable
      />
    </div>
    <data-table :data="tableData" height="calc(100vh - 232px)">
      <el-table-column
        label="Name"
        prop="human_name"
        width="275px"
        :sortable="true"
      >
        <template slot-scope="scope">
          <link-button
            type="primary"
            :to="{ name: 'CompanyOverview', params: { cid: scope.row.id } }"
          >
            {{ scope.row.human_name }}
          </link-button>
        </template>
      </el-table-column>
      <el-table-column
        label="Status"
        :filters="statusFilterOptions"
        :filter-method="statusFilterHandler"
      >
        <template slot-scope="scope">
          <el-tag
            :type="getStatusTagType(scope.row.status)"
            size="medium"
            disable-transitions
          >
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Pending"
        prop="pending"
        :sortable="true"
      />
      <el-table-column
        label="Trial"
        prop="trial"
        :sortable="true"
      />
      <el-table-column
        label="Onboarding"
        prop="onboarding"
        :sortable="true"
      />
      <el-table-column
        label="Active"
        prop="active"
        :sortable="true"
      />
      <el-table-column
        label="Offboarding"
        prop="offboarding"
        :sortable="true"
      />
      <el-table-column
        label="Inactive"
        prop="inactive"
        :sortable="true"
      />
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import LinkButton from '@/components/buttons/LinkButton'

import { getStatusTagType } from '@/utils/rooof'

export default {
  name: 'CompanyListTable',
  components: {
    'data-table': DataTable,
    'link-button': LinkButton
  },
  data () {
    return {
      searchValue: ''
    }
  },
  computed: {
    companies () {
      return this.$store.state.companies.data
    },
    tableData () {
      if (this.searchValue === '') {
        return this.companies
      }
      const searchValue = this.searchValue.toLowerCase()
      return this.companies.filter(company => {
        let include = false
        include |= company.human_name.toLowerCase().includes(searchValue)
        if (company.salesperson__name) {
          include |= company.salesperson__name.toLowerCase().includes(searchValue)
        }
        return include
      })
    },
    statusFilterOptions () {
      const status = new Set()
      for (const company of this.companies) {
        status.add(company.status)
      }
      return Array.from(status).sort().map(status => ({
        text: status.charAt(0).toUpperCase() + status.slice(1),
        value: status
      }))
    }
  },
  created () {
    this.getStatusTagType = getStatusTagType
  },
  methods: {
    /**
     * Filter method for status column.
     * Decides which rows to display.
     *
     * @param {String} filterValue - the cell value
     * @param {Object} row - row object
     * @param {Object} col - column object
     */
    statusFilterHandler (filterValue, row, col) {
      return row.status === filterValue
    }
  }
}
</script>

<style scoped>
.table-filters {
  margin-top: 1em;
  width: 400px;
}
.company-link {
  cursor: pointer;
  display: inline-block;
  word-break: normal;
}
.company-link:hover {
  text-decoration: underline;
}
</style>
