<template>
  <div>
    <el-row
      type="flex"
      justify="space-between"
      align="middle"
    >
      <h1 class="title">
        Companies
      </h1>
      <company-list-controls
        :loading-company-export="loadingCompanyExport"
        :loading-property-export="loadingPropertyExport"
        @exportCompanyData="exportCompanyData"
        @exportPropertyData="exportPropertyData"
      />
    </el-row>
    <company-list-table />
    <data-export-dialog
      :show="showExportDialog"
      :data="data"
      :data-type="dataType"
      @close="showExportDialog = false"
    />
  </div>
</template>

<script>
import CompanyListTable from './_components/CompanyListTable'
import CompanyListControls from './_components/CompanyListControls'
import DataExportDialog from '@/components/dialogs/DataExportDialog'

import RooofAPI from '@/services/api/rooof'
import InvoicesAPI from '@/services/api/invoices'
import { formatAddress } from '@/utils/rooof'
import { getSubscriptionsString, getTotalMonthlyBillingRate } from '@/utils/subscriptions'

export default {
  name: 'CompanyList',
  components: {
    'company-list-table': CompanyListTable,
    'company-list-controls': CompanyListControls,
    'data-export-dialog': DataExportDialog
  },
  data () {
    return {
      data: [], // for export
      dataType: '',
      loadingCompanyExport: false,
      loadingPropertyExport: false,
      showExportDialog: false
    }
  },
  methods: {
    /**
     * Fetch all companies from the api, then display
     * the export dialog.
     */
    async exportCompanyData () {
      this.dataType = 'company'
      try {
        this.loadingCompanyExport = true
        this.data = await RooofAPI.companies.list()
        this.loadingCompanyExport = false
        this.showExportDialog = true
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loadingCompanyExport = false
      }
    },
    /**
     * Fetch all properties from the api, then display
     * the export dialog.
     */
    async exportPropertyData () {
      this.dataType = 'property'
      try {
        this.loadingPropertyExport = true
        this.data = await this.formatPropertyReport(await RooofAPI.properties.list())
        this.loadingPropertyExport = false
        this.showExportDialog = true
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loadingCompanyExport = false
      }
    },
    /**
     * Generates a list of property objects for export.
     *
     * @param {Array} data - list of property objects
     * @returns {Array} - list of formatted properties for export
     */
    async formatPropertyReport (data) {
      // get all invoicing groups
      const response = await InvoicesAPI.groups.list()
      const getInvoicingGroups = (groups, invoicingGroups = {}) => {
        for (const group of groups) {
          invoicingGroups[group.id] = group
          if (group.children.length) {
            getInvoicingGroups(group.children, invoicingGroups)
          }
        }
        return invoicingGroups
      }
      const invoicingGroups = getInvoicingGroups(response)

      return data.map(property => {
        const company = property.company ? this.$store.getters.getCompanyById(property.company) : {}
        const customerID = property.master_invoice
          ? invoicingGroups[property.invoicing_group].zoho_customer_id
          : property.zoho_customer_id
        return {
          'Company': company.human_name,
          'Property Name': property.name,
          'Property Email': property.email_contact,
          'Account Manager': company.account_manager__name,
          'Salesperson': company.salesperson__name,
          'Status': property.status,
          'Start Date': property.start_date,
          'End Date': property.end_date,
          'Billing Address': formatAddress(property.billing_address),
          'Billing Address: Street': property.billing_address.street,
          'Billing Address: City': property.billing_address.city,
          'Billing Address: State': property.billing_address.state,
          'Billing Address: Zip': property.billing_address.postal,
          'Billing Address: Country': property.billing_address.country,
          'Billing Address: Latitude': property.billing_address.latitude,
          'Billing Address: Longitude': property.billing_address.longitude,
          'Property Address': formatAddress(property.property_address),
          'Property Address: Street': property.property_address.street,
          'Property Address: City': property.property_address.city,
          'Property Address: State': property.property_address.state,
          'Property Address: Zip': property.property_address.postal,
          'Property Address: Country': property.property_address.country,
          'Property Address: Latitude': property.property_address.latitude,
          'Property Address: Longitude': property.property_address.longitude,
          'Product': getSubscriptionsString(property),
          'Unit Count': property.unit_count,
          'Monthly Rate': getTotalMonthlyBillingRate(property),
          'Notes': property.notes,
          'Customer ID': customerID ? customerID.toString() : ''
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin: 0;
  font-size: 2rem;
}
</style>
